.container 
    height: 100vh
    display: flex
    align-items: center
    justify-content: center


.img 
    
    height: 100vh
    perspective: 300px
    


.img-wrapper 
    position: relative
    z-index: 1
    height: 100vh
    
    transform-style: preserve-3d


.img-inner 
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    z-index: 0
    padding: 60px
    height: 100vh


    
    //////////////// ELIMINAR /////////////////

.relleno
    width: 100%
    height: 100vh
    position: relative
    top: 700px