#section_1
    min-height: 100vh
    @media screen and ( max-width: 768px)
        min-height: 75vh
    
    //////
    background: url('https://ik.imagekit.io/ro48hkvo8/fondo_SFrBepL5J.png?ik-sdk-version=javascript-1.4.3&updatedAt=1663974535238')
    background-repeat: no-repeat
    background-position: center
    
    ///////
    overflow: hidden
    position: relative
    
    background-size: 100%

    @media screen and ( max-width: 795px )
        background-size: cover
    

        
///// HOME TITLE    //////////////////

.hero-section
    display: flex
    justify-content: center
    flex-wrap: wrap

    height: 100vh
    @media screen and ( max-width: 768px)
        height: 75vh
    h1
        font-size: 4vw
        color: #ffffff
        font-family: 'akira-3'
        white-space: nowrap
        position: relative

        @media screen and ( max-width: 768px)
            font-size: 8vw
        

    h2
        font-size: 6vw
        color: #ffffff
        font-family: 'akira-2'
        white-space: nowrap
        position: relative
        bottom: 20px
        @media screen and ( max-width: 768px)
            font-size: 11vw



.hero--arrow
    
    background-color: #ffffff
    width: 100%
    height: 100%
    position: relative

.arrow--container
    overflow: hidden
    width: 1px
    height: 50px
    position: relative
    top: 130px
    #arrow_1
        background-color: #ffffff
        bottom: 150px
    

    
// .line-1
        
//     font-family: 'akira-3'
//     font-size: 5vw
//     padding: 0
//     margin: 0
    
//     color: #ffffff
// .line-2
        
//     font-family: 'akira-2'
//     font-size: 8vw
//     padding: 0
//     margin: 0


            

// .home__subtitle
//     position: relative
//     bottom: 40px
//     @media screen and ( max-width: 768px  )
        
//         bottom: 18px
//     p
//         font-family: 'akira-2'
//         color: #ffffff
//         font-size: 5vw
//         padding: 0
//         margin: 0
//         white-space: nowrap

//         @media screen and ( max-width: 768px )
//             font-size: 8vw
            
////// HOME CTA   //////////////////

    // #cta_1
    //     position: relative
    //     width: 100%
    //     display: block
    //     top: 50px


///////// HOME LOGO  ///////////// 



#cta1_header_button
    

    height: 50px
    
#cta_button_link
    position: absolute
    height: 100%
    font-family: 'akira-3'
    color: #ffffff
    font-size: 15px
    text-decoration: none
    letter-spacing: 2px
    
    background: linear-gradient(217deg, #ff0000, #421515ad 70.71%),linear-gradient(127deg, #000000, #000000 70.71%),linear-gradient(336deg, #630505, #ffffff81 70.71%)

        

    
  
            
            

    



