@import "https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap";
.cursor {
  width: 15px;
  height: 15px;
  mix-blend-mode: difference;
  z-index: 40;
  background-color: #fff;
  border-radius: 50%;
  position: fixed;
}

@media screen and (max-width: 768px) {
  .cursor {
    display: none;
  }
}

.bigcursor {
  transition: scale .5s ease-in-out;
  transform: scale(3);
}

.smallcursor {
  transition: scale .5s ease-in-out;
  transform: scale(1);
}

.expand {
  border: 1px solid #ff000060;
  animation: .9s forwards cursorAnim3;
}

@keyframes cursorAnim {
  from {
    height: 15px;
    width: 15px;
  }

  to {
    height: 30px;
    width: 30px;
  }
}

@keyframes cursorAnim2 {
  from {
    height: 30px;
    width: 30px;
  }

  to {
    height: 15px;
    width: 15px;
  }
}

@keyframes cursorAnim3 {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(3);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

#section_1 {
  min-height: 100vh;
  background: url("https://ik.imagekit.io/ro48hkvo8/fondo_SFrBepL5J.png?ik-sdk-version=javascript-1.4.3&updatedAt=1663974535238") center / 100% no-repeat;
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  #section_1 {
    min-height: 75vh;
  }
}

@media screen and (max-width: 795px) {
  #section_1 {
    background-size: cover;
  }
}

.hero-section {
  height: 100vh;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

@media screen and (max-width: 768px) {
  .hero-section {
    height: 75vh;
  }
}

.hero-section h1 {
  color: #fff;
  white-space: nowrap;
  font-family: akira-3;
  font-size: 4vw;
  position: relative;
}

@media screen and (max-width: 768px) {
  .hero-section h1 {
    font-size: 8vw;
  }
}

.hero-section h2 {
  color: #fff;
  white-space: nowrap;
  font-family: akira-2;
  font-size: 6vw;
  position: relative;
  bottom: 20px;
}

@media screen and (max-width: 768px) {
  .hero-section h2 {
    font-size: 11vw;
  }
}

.hero--arrow {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: relative;
}

.arrow--container {
  width: 1px;
  height: 50px;
  position: relative;
  top: 130px;
  overflow: hidden;
}

.arrow--container #arrow_1 {
  background-color: #fff;
  bottom: 150px;
}

#cta1_header_button {
  height: 50px;
}

#cta_button_link {
  height: 100%;
  color: #fff;
  letter-spacing: 2px;
  background: linear-gradient(217deg, red, #421515ad 70.71%), linear-gradient(127deg, #000, #000 70.71%), linear-gradient(336deg, #630505, #ffffff81 70.71%);
  font-family: akira-3;
  font-size: 15px;
  text-decoration: none;
  position: absolute;
}

#section_2 {
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(127deg, red, #421515ad 70.71%), linear-gradient(127deg, #000, #000 70.71%), linear-gradient(336deg, #630505, #ffffff81 70.71%);
}

.img_section2 {
  height: 450px;
  background: url("https://ik.imagekit.io/ro48hkvo8/bentleycolor_k6lP9vSUr.png?ik-sdk-version=javascript-1.4.3&updatedAt=1663974535394") center / 100% no-repeat;
  transition: all .5s ease-in-out;
}

@media screen and (max-width: 768px) {
  .img_section2 {
    height: 300px;
  }
}

.thin_line {
  height: .5px;
  width: 100%;
  mix-blend-mode: difference;
  background-color: #fff;
  transition: all 1s ease-in-out;
}

.epigraph, .intro_title {
  transition: all 1s ease-in-out;
}

.epigraph {
  font-family: cera-1;
  line-height: 14px;
}

.e-white {
  color: #fff;
  font-size: 11px;
}

.e-black {
  color: #000;
  font-size: 20px;
}

.l-white {
  background-color: #fff;
}

.l-black {
  background-color: #000;
}

#top_epigraph {
  position: relative;
  top: 20px;
}

.s2_intro {
  height: 120vh;
}

@media screen and (max-width: 768px) {
  .s2_intro {
    height: 100vh;
  }

  #introLeft {
    background-color: #2b2b2b;
  }
}

#introRight h2 {
  color: #fff;
  margin: 0;
  padding: 0;
  font-size: 44px;
  position: relative;
}

#introRight #intro_title_1 {
  font-family: akira-3;
}

#introRight #intro_title_2 {
  font-family: akira-2;
  bottom: 10px;
}

#introRight p {
  color: #fff;
  font-family: cera-3;
  font-size: 15px;
  font-weight: 100;
  line-height: 24px;
}

#img_section2[data-emergence="hidden"] {
  opacity: 0;
}

#img_section2[data-emergence="visible"] {
  opacity: 1;
}

.thin_line[data-emergence="hidden"] {
  width: 0%;
}

.thin_line[data-emergence="visible"] {
  width: 100%;
}

.epigraph[data-emergence="hidden"] {
  opacity: 0;
  top: 10px;
}

.epigraph[data-emergence="visible"] {
  opacity: 1;
  top: 0;
}

.intro_title[data-emergence="hidden"] {
  opacity: 0;
  top: 10px;
}

.intro_title[data-emergence="visible"] {
  opacity: 1;
  top: 0;
}

#section_3 {
  min-height: 100vh;
  width: 100%;
  background-color: #1e1e1e;
}

.slider_p_container {
  height: 100%;
  position: relative;
}

.slider-paragraph {
  white-space: nowrap;
  color: #fff;
  font-family: akira-3;
  font-size: 100px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .slider-paragraph {
    font-size: 42px;
    transition: all 1s ease-in-out;
  }
}

.slider-paragraph .fChar {
  font-family: akira-2;
}

#slider_p2 {
  z-index: 3;
  bottom: 270px;
  right: 600px;
}

@media screen and (max-width: 768px) {
  #slider_p2 {
    font-family: akira-2;
    top: 0;
    right: 0;
  }
}

#slider_p1 {
  z-index: 1;
  top: 200px;
  right: 100px;
}

@media screen and (max-width: 768px) {
  #slider_p1 {
    top: 30px;
    bottom: 0;
    right: 0;
  }
}

.profileImage {
  z-index: 2;
  position: absolute;
  top: 100px;
}

#sworks_MOBILE {
  height: 100vh;
}

#sworks_MOBILE p {
  color: #fff;
  font-family: cera-3;
}

.slider-paragraph[data-emergence="hidden"] {
  opacity: 0;
  top: 20px;
}

.slider-paragraph[data-emergence="visible"] {
  opacity: 1;
  top: 0;
}

.nav-bar {
  width: 100%;
  z-index: 100;
  mix-blend-mode: normal;
  position: fixed;
}

.nav-bar_bg-container {
  width: 100%;
  z-index: 90;
  position: fixed;
}

.nav-bar_bg-container .nav-bar_bg {
  height: 0;
  background-color: #000;
}

.navBar-collapse__links {
  height: 0;
  z-index: 40;
  flex-wrap: wrap;
  place-content: center;
  display: none;
  position: absolute;
}

.navBar-collapse__links a {
  color: #fff;
  outline: none;
  font-family: akira-2;
  font-size: 40px;
  text-decoration: none;
}

.navBar-collapse__links a:hover {
  color: #fff;
  outline: none;
  font-family: akira-3;
  text-decoration: none;
}

#menu_container {
  mix-blend-mode: difference;
  background: none;
  position: relative;
}

#menu_container #left_brand, #menu_container #menu_logo, #menu_container #burguer_button {
  position: relative;
}

#menu_container #menu_logo {
  z-index: 2;
  width: 50px;
  margin: auto;
}

@media screen and (max-width: 768px) {
  #menu_container #menu_logo {
    margin: 0 50% 0 10px;
  }
}

#menu_container #left_brand, #menu_container #burguer_button {
  z-index: 1;
  width: 40%;
}

#menu_container #left_brand {
  color: #fff;
  font-family: akira-1;
  font-size: 10px;
}

@media screen and (max-width: 480px) {
  #menu_container #left_brand {
    font-size: 2vw;
  }
}

@media screen and (max-width: 768px) {
  #menu_container #left_brand {
    display: none;
  }
}

#menu_container #left_brand p {
  mix-blend-mode: difference;
  transition: all .3s;
}

#menu_container #left_brand p:hover {
  cursor: pointer;
}

#menu_container #burguer_button {
  position: relative;
}

#menu_container #burguer_button a {
  color: #fff;
  text-align: center;
  mix-blend-mode: difference;
  margin-right: 10px;
  font-family: akira-1;
  font-size: 10px;
  text-decoration: none;
  transition: all .3s;
  position: relative;
  left: 40px;
  overflow: hidden;
}

@media screen and (max-width: 800px) {
  #menu_container #burguer_button a {
    display: none;
  }
}

#menu_container #burguer_button a:hover {
  color: #ff3a3a;
}

#menu_container #burguer_button #burguer_container {
  width: 50px;
  height: 50px;
  overflow: hiddenlinea;
  border-radius: 50%;
  padding: 5px;
  position: relative;
  left: 25%;
}

@media screen and (max-width: 768px) {
  #menu_container #burguer_button #burguer_container {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: #ffffff4d;
  }
}

#menu_container #burguer_button #burguer_container:hover {
  cursor: pointer;
}

#menu_container #burguer_button #burguer_container div {
  width: 70%;
  height: 2px;
  background: #fff;
  border-radius: 10px;
  margin-bottom: 4px;
  transition: all .5s;
  position: relative;
}

#menu_container #burguer_button #burguer_container div:hover {
  background: red;
}

@media screen and (max-width: 768px) {
  #menu_container #burguer_button #burguer_container div {
    background: #000;
  }
}

#menu_container #burguer_button #burguer_container #menu_line-3 {
  opacity: 0;
  width: 55%;
  top: 9px;
  transform: rotate(45deg)scaleY(0);
}

#menu_container #burguer_button #burguer_container #menu_line-4 {
  opacity: 0;
  width: 55%;
  bottom: 9px;
  transform: rotate(-45deg)scaleY(0);
}

#menu_container #burguer_button #burguer_container:hover #menu_line-2 {
  right: 2px;
}

#menu_container #burguer_button #burguer_container:hover #menu_line-1 {
  left: 2px;
}

.preload {
  width: 100%;
  height: 100vh;
  z-index: 100000;
  background: #000;
  flex-wrap: wrap;
  place-content: start center;
  display: flex;
  position: fixed;
  top: 0;
  overflow: hidden;
}

.preload .preload-background {
  width: 100%;
  height: 100%;
  position: relative;
}

.preload #preload_bg__1 {
  background: #810000;
}

.preload #preload_bg__2 {
  background: #4e0000;
}

.preload #preload_bg__3 {
  background: #1a0000;
}

.preload #loading_chars {
  height: 100%;
  white-space: nowrap;
  color: #fff;
  z-index: 2;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-family: akira-3;
  font-size: 50px;
  position: absolute;
}

@media screen and (max-width: 768px) {
  .preload #loading_chars {
    font-size: 10vw;
  }
}

#loading_text {
  position: relative;
  top: 35vh;
}

.container {
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
}

.img {
  height: 100vh;
  perspective: 300px;
}

.img-wrapper {
  z-index: 1;
  height: 100vh;
  transform-style: preserve-3d;
  position: relative;
}

.img-inner {
  z-index: 0;
  height: 100vh;
  padding: 60px;
  position: absolute;
  inset: 0;
}

.relleno {
  width: 100%;
  height: 100vh;
  position: relative;
  top: 700px;
}

main {
  z-index: 1;
  position: relative;
}

#section_4 {
  background-color: #fff;
  padding-top: 100px;
}

@media screen and (max-width: 768px) {
  #section_4 {
    padding-top: 10px;
  }
}

#section_5 {
  height: 70vh;
  width: 100%;
  background: linear-gradient(127deg, red, #421515ad 70.71%), linear-gradient(127deg, #000, #000 70.71%), linear-gradient(336deg, #630505, #ffffff81 70.71%);
}

#all_works_link {
  color: #fff;
  white-space: nowrap;
  outline: none;
  font-family: cera-2;
  font-size: 43px;
  text-decoration: none;
}

#all_works_link:hover {
  outline: none;
  text-decoration: none;
}

#right_arrow > div {
  height: 60px;
  width: 60px;
  border: .7px solid #fff;
  border-radius: 100%;
  transition: border-color .7s;
}

.right--arrow path {
  fill: #fff;
}

#link_line_container {
  overflow: hidden;
}

#link_line {
  width: 100%;
  height: 1px;
  background-color: #fff;
  position: relative;
  right: 100%;
}

#arrow_lines_container {
  height: 60%;
}

.arrow_line {
  height: 1px;
  width: 70%;
  background-color: #fff;
  border-radius: 25px;
}

#arrow_line__1 {
  position: relative;
  top: 5.2px;
  transform: rotate(-35deg);
}

#arrow_line__2 {
  position: relative;
  bottom: 5.2px;
  transform: rotate(35deg);
}

#section_6 {
  min-height: 120vh;
  width: 100%;
  background-color: #1e1e1e;
}

#section_6 footer {
  min-height: 100vh;
  padding-top: 100px;
}

#section_6 h5 {
  color: #fff;
  font-family: akira-3;
  font-size: 44px;
  line-height: 35px;
  transition: all .6s ease-in-out;
  position: relative;
}

#section_6 span {
  color: #fff;
  font-family: cera-4;
  font-size: 13px;
}

#section_6 .ftr-caption, #section_6 a {
  color: #fff;
  opacity: .25;
  font-family: cera-3;
  font-size: 13px;
}

#section_6 .text-blind {
  position: relative;
  overflow: hidden;
}

#section_6 .blind-title[data-emergence="hidden"] {
  top: 60px;
}

#section_6 .blind-title[data-emergence="visible"] {
  top: 0;
}

#city_picture {
  height: 60vh;
  background: url("MDP.a20cf731.jpg") center / cover no-repeat;
}

article {
  min-height: 100vh;
  margin: 0;
  padding: 10px 0;
}

@media screen and (max-width: 768px) {
  article {
    min-height: 8vh;
  }
}

article .work--model, article .work--brand {
  margin: 0;
  padding: 0;
  font-family: akira-3;
  font-size: 44px;
}

article .work--number {
  color: #a7a7a7;
  white-space: nowrap;
  margin-bottom: 20px;
  font-family: akira-1;
  font-size: 25px;
}

article .work--brand {
  color: #dbdbdb;
}

article .mercedes-gtc_work {
  height: 60vh;
  background: url("https://ik.imagekit.io/ro48hkvo8/amg_gtc_rB2bZCF2dS.png?ik-sdk-version=javascript-1.4.3&updatedAt=1663974534294") center / cover no-repeat;
}

@media screen and (max-width: 768px) {
  article .mercedes-gtc_work {
    height: 50vh;
  }
}

article .work-header--img {
  height: 60vh;
  background: url("https://ik.imagekit.io/ro48hkvo8/supraGR_f4dwNUPSr.png?ik-sdk-version=javascript-1.4.3&updatedAt=1663974535009") center / cover no-repeat;
}

@media screen and (max-width: 768px) {
  article .work-header--img {
    height: 50vh;
  }
}

article .mclaren_img {
  height: 60vh;
  background: url("mclaren.88760119.jpeg") center / cover no-repeat;
}

@media screen and (max-width: 768px) {
  article .mclaren_img {
    height: 50vh;
  }
}

article .ducati-panigale_img {
  height: 60vh;
  background: url("https://ik.imagekit.io/ro48hkvo8/ducatiPanigale_1_PqChG8gxc.png?ik-sdk-version=javascript-1.4.3&updatedAt=1663974535105") center / cover no-repeat;
}

@media screen and (max-width: 768px) {
  article .ducati-panigale_img {
    height: 50vh;
  }
}

article .work-fl_paragraph {
  font-family: cera-4;
  font-size: 23px;
}

article .work-sl_paragraph {
  font-family: cera-3;
  font-size: 19px;
  bottom: 20px;
}

article a {
  outline: none;
  text-decoration: none;
}

.work_epigraph p {
  font-size: 15px;
}

.wk-t {
  transition: opacity .6s;
}

.wk-t[data-emergence="hidden"] {
  opacity: 0;
}

.wk-t[data-emergence="visible"] {
  opacity: 1;
}

@font-face {
  font-family: akira-1;
  src: url("Akira-Bold.61c840db.otf");
  font-style: regular;
  font-weight: 100;
}

@font-face {
  font-family: cera-1;
  src: url("CircularStdBook.fbd08f50.ttf");
  font-style: regular;
  font-weight: 100;
}

@font-face {
  font-family: cera-2;
  src: url("CircularStdBold.4a6390db.ttf");
  font-style: regular;
  font-weight: 100;
}

@font-face {
  font-family: cera-3;
  src: url("CeraProLight.509f0ba8.otf");
  font-style: regular;
  font-weight: 100;
}

@font-face {
  font-family: cera-4;
  src: url("CeraProMedium.9315d47c.otf");
  font-style: regular;
  font-weight: 100;
}

@font-face {
  font-family: akira-2;
  src: url("Akira-Outline.8633cfbe.otf");
  font-style: regular;
  font-weight: 100;
}

@font-face {
  font-family: akira-3;
  src: url("Akira-SuperBold.1dc0d2e4.otf");
  font-style: regular;
  font-weight: 100;
}

.resp-div, .resp-end {
  flex-wrap: wrap;
  place-content: center;
  display: flex;
}

.resp-2 {
  flex-wrap: wrap;
  place-content: center start;
  display: flex;
}

.resp-3 {
  flex-wrap: wrap;
  place-content: start center;
  display: flex;
}

@media screen and (max-width: 768px) {
  #about-txt.resp-3 {
    align-content: center;
  }
}

.resp-4 {
  flex-wrap: wrap;
  place-content: start;
  display: flex;
}

.resp-div-5 {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

.resp-6 {
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  display: flex;
}

.resp-7 {
  flex-wrap: wrap;
  justify-content: center;
  align-items: start;
  display: flex;
}

.resp-8 {
  flex-wrap: wrap;
  justify-content: start;
  align-items: start;
  display: flex;
}

.resp-div-9 {
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.resp-div-10 {
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

.resp-10 {
  flex-wrap: nowrap;
  justify-content: start;
  align-items: center;
  display: flex;
}

.resp-11 {
  flex-wrap: nowrap;
  justify-content: center;
  align-items: start;
  display: flex;
}

.resp-12 {
  flex-wrap: nowrap;
  justify-content: start;
  align-items: start;
  display: flex;
}

#floatImg {
  width: 100px;
}

.float {
  opacity: 1;
  z-index: 100;
  pointer-events: none;
  transition: opacity .2s;
  position: fixed;
}

*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

html {
  overflow-x: hidden;
}

html, body {
  color: #fff;
  margin: 0;
  padding: 0;
  font-family: akira-1;
}

body {
  width: 100%;
  -webkit-user-select: none;
  user-select: none;
  background: #161616;
  overflow: hidden;
}

body::-webkit-scrollbar {
  width: 3px;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 10px 10px 6px #000;
}

body::-webkit-scrollbar-thumb {
  background-color: red;
  outline: 1px solid red;
}

.svg-container {
  width: 70%;
  position: relative;
  top: 400px;
  overflow: hidden;
}

.text-circle {
  z-index: 3;
  width: 40px;
  mix-blend-mode: normal;
  position: fixed;
  top: 90vh;
  left: 95vw;
}

.text-circle img {
  width: 40px;
}

.red-line {
  width: 1px;
  background: red;
}

#home_Title {
  margin-top: 200px;
}

/*# sourceMappingURL=index.d48cefda.css.map */
