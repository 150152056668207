
.nav-bar
    width: 100%
    position: fixed
    z-index: 100
    mix-blend-mode: normal

.nav-bar_bg-container
    width: 100%
    position: fixed
    z-index: 90

    .nav-bar_bg
        height: 0
        background-color: #000000
    
    

.navBar-collapse__links
    height: 0vh
    display: none
    justify-content: center
    align-content: center
    flex-wrap: wrap
    position: absolute
    z-index: 40

    a
        font-family: 'akira-2'
        color: #ffffff
        font-size: 40px
        outline: none
        text-decoration: none
        &:hover
            font-family: 'akira-3'
            outline: none
            text-decoration: none
            color: #ffffff

   

#menu_container
    position: relative
    background: transparent
    mix-blend-mode: difference
    

    #left_brand,
    #menu_logo,
    #burguer_button
        position: relative
        

    ///////  FIRPO DRAWINGS LOGO   ///////
    #menu_logo
        z-index: 2
        margin: auto
        width: 50px
        
        @media screen and ( max-width: 768px )
            margin: 0 50% 0 10px
       

        
        
        
    /////// BOTH SIDES CONTAINERS  //////

    #left_brand,
    #burguer_button
        z-index: 1
        width: 40%
        
        

    /////// FIRPO DRAWINGS BRAND  ///////

    #left_brand
        font-size: 10px
        font-family: 'akira-1'
        color: #ffffff
        
        @media screen and ( max-width: 480px )
            font-size: 2vw
        @media screen and ( max-width: 768px )
            display: none

        p
            mix-blend-mode: difference
            transition: 0.3s
        p:hover
            cursor: pointer
   
        
        
    ////// RIGHT BURGUER BUTTON MENU ////////

    #burguer_button
        position: relative
        
        a
            position: relative
            left: 40px
            margin-right: 10px
            overflow: hidden

            font-family: 'akira-1'
            font-size: 10px
            color: #ffffff
            text-decoration: none
            text-align: center

            transition: 0.3s

            mix-blend-mode: difference

            
            
            @media screen and ( max-width: 800px )
                display: none

        a:hover
            color: #ff3a3a
            
                


        
        #burguer_container
            width: 50px
            height: 50px
            border-radius: 50%
            position: relative
            left: 25%
            overflow: hiddenlinea
            padding: 5px

            @media screen and ( max-width: 768px  )
                // background: linear-gradient(217deg, #ff0000, #421515ad 70.71%),linear-gradient(127deg, #000000, #000000 70.71%),linear-gradient(336deg, #630505, #ffffff81 70.71%)
                background-color: rgba(255, 255, 255, 0.3)
                backdrop-filter: blur(10px) 
                -webkit-backdrop-filter: blur(10px)
            &:hover
                cursor: pointer
            div
                width: 70%
                height: 2px
                background: #ffffff
                margin-bottom: 4px
                border-radius: 10px
                position: relative
                transition: 0.5s
                &:hover
                    background: #ff0000
                @media screen and ( max-width: 768px  )
                    background: #000000

                    
            #menu_line-3
                top: 9px
                transform: rotate(45deg) scaleY(0)
                opacity: 0
                width: 55%
                
            #menu_line-4
                bottom: 9px
                transform: rotate(-45deg) scaleY(0)
                opacity: 0
                width: 55%
            
                

        #burguer_container:hover
            #menu_line-2
                right: 2px
            #menu_line-1
                left: 2px
            
