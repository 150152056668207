.cursor
    width: 15px
    height: 15px
    background-color: #ffffff
    border-radius: 50%
    position: fixed
    mix-blend-mode: difference
    z-index: 40
    // transition: 0.2
    // transition-delay: 2
    @media screen and ( max-width: 768px )
        display: none

.bigcursor
    transform: scale(3)
    transition: scale 0.5s ease-in-out
.smallcursor
    transform: scale(1)
    transition: scale 0.5s ease-in-out



// .cursor::after
//     content: ''
//     width: 18px
//     height: 18px
//     top: -2.6px
//     left: -2.6px
//     border-radius: 50%
//     background-color: #ffffff
    
//     animation: cursorAnim2 .9s infinite alternate
//     position: absolute

.expand
    animation: cursorAnim3 .9s forwards
    border: 1px solid #ff000060

@keyframes cursorAnim
    from 
        height: 15px
        width: 15px

    to
        height: 30px
        width: 30px

@keyframes cursorAnim2
    from 
        height: 30px
        width: 30px

    to
        height: 15px
        width: 15px

@keyframes cursorAnim3
    0% 
        transform: scale(1)

    50%
        transform: scale(3)
    100%
        transform: scale(1)
        opacity: 0