#section_5
    height: 70vh
    width: 100%
    background: linear-gradient(127deg, #ff0000, #421515ad 70.71%), linear-gradient(127deg, #000000, #000000 70.71%),linear-gradient(336deg, #630505, #ffffff81 70.71%)
    // background-color: #9E0000
    


#all_works_link
    font-family: 'cera-2'
    color: #ffffff
    font-size: 43px
    text-decoration: none
    outline: none
    white-space: nowrap
    &:hover
        text-decoration: none
        outline: none
        

#right_arrow > div
    height: 60px
    width: 60px
    border: 0.7px solid
    border-color: #ffffff 
    border-radius: 100%
    transition: border-color .7s

.right--arrow path
    fill: #ffffff

#link_line_container
    overflow: hidden
    
#link_line
    width: 100%
    height: 1px
    background-color: #ffffff
    position: relative
    right: 100%

#arrow_lines_container
    
    height: 60%
.arrow_line
    background-color: #ffffff
    height: 1px
    border-radius: 25px
    width: 70%



#arrow_line__1
    transform: rotate(-35deg)
    position: relative
    top: 5.2px
#arrow_line__2
    transform: rotate(35deg)
    position: relative
    bottom: 5.2px
