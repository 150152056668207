#section_6
    min-height: 120vh
    width: 100%
    // background: linear-gradient(127deg, #ff0000, #421515ad 70.71%), linear-gradient(127deg, #000000, #000000 70.71%),linear-gradient(336deg, #630505, #ffffff81 70.71%)
    background-color: #1E1E1E

    footer
        min-height: 100vh
        padding-top: 100px

    h5
        font-family: 'akira-3'
        font-size: 44px
        color: #ffffff
        line-height: 35px
        position: relative
        transition: 0.6s ease-in-out
    span
        font-family: 'cera-4'
        font-size: 13px
        color: #ffffff
        
    .ftr-caption,a
        font-family: 'cera-3'
        font-size: 13px
        color: #ffffff
        opacity: 25%


    .text-blind
        overflow: hidden
        position: relative
            

    .blind-title[data-emergence=hidden] 
        top: 60px
            
    .blind-title[data-emergence=visible] 
        top: 0px

    


        

#city_picture
    height: 60vh
    background: url(../img/MDP.jpg)
    background-size: cover
    background-repeat: no-repeat
    background-position: center