.preload
    position: fixed
    top: 0
    width: 100%
    height: 100vh
    background: #000000
    display: flex
    justify-content: center
    align-content: start
    flex-wrap: wrap
   
    z-index: 100000

    overflow: hidden
    
    .preload-background
        width: 100%
        height: 100%
        position: relative
        
    #preload_bg__1
        
        background: #810000

    #preload_bg__2
        
        background: #4e0000

    #preload_bg__3
        
        background: #1a0000

    #loading_chars
        height: 100%
        white-space: nowrap
        font-family: 'akira-3'
        color: #ffffff
        font-size: 50px

       
        margin-top: 0
        margin-bottom: 0
        padding-top: 0
        padding-bottom: 0

        position: absolute
        z-index: 2

        @media screen and ( max-width: 768px )
            font-size: 10vw
            

        


    // #loader_paragraph__2
    //     top: 100px


#loading_text
    position: relative
    top: 35vh

    